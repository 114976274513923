jQuery(document).ready(function(){

    jQuery('body').on('click','.gdprSubmit',function(e){

        answer = jQuery(this).attr('id');

        if( answer=='gdprAgree' )
            answer=1;
        else if( answer=='gdprDisagree' )
            answer=2;
        else
            answer=0;

        e.preventDefault();

        jQuery('form#gdprForm').ajaxSubmit({
            type:"post",
            data:{ gdpr_consent : answer },
            dataType: "json",
            success: function(data){
            },
        });

        jQuery("#gdpr_modal").modal('hide');
    });

    jQuery('#gdprPreDisagree').click(function() {
        jQuery('.gdpr_initial').hide();
        jQuery('.gdpr_secondary').show();
    });

    jQuery('.gdprCancel').click(function() {
        jQuery('.gdpr_initial').show();
        jQuery('.gdpr_secondary').hide();
    });

    jQuery('.gdprLearnMore').click(function() {
        jQuery('#gdpr_opted_out').modal('hide');
        jQuery("#gdpr_modal").modal({backdrop: 'static', keyboard: false, show: true});
    });
    jQuery('.gdprCancel').click(function() {
        jQuery('#gdpr_opted_out').modal('hide');
    });
});