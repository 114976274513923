jQuery(function () {

    $('#back_button_check').val(1);

    if (isMobileBrowser()) {
        // Avoid add a .mobile class to the body element if on mobile
        $('body').addClass('is-mobile').removeClass('is-not-mobile');
    }

    $('body').on('click', '.formSubmit', function (e) {
        e.preventDefault();
        $(this).closest('form').submit();
    });

    //------------------------- REPLACE MISSING IMAGES --------------------------//

    $(".pageStory img").on('error', function () {
        $(this).unbind("error").css("display", "none");
        $('.pageStory .relatedStories img').css('display', 'block');
    });

    //------------- TEMPORARY FUNCTIONS -------------//

    // DEGUG
    /*
	$('body').prepend('<div id="debug" />');
	$(window).resize( function() {
		$('#debug').html( $(window).width() );
	}); $(window).resize();
  	*/

    // REWRITE LINKS TO WORK PROPERLY ON THE SERVER
    if (location.href.indexOf('arsen.ws') != -1) {
        $('a').not('[href^="tel"]').not('[href^="mailto"]').each(function () {
            var oNewLink = 'http://arsen.ws/bisnow' + $(this).attr('href');
            $(this).attr('href', oNewLink);
        });
    }


    //---------------------- AUXILIARY ----------------------//


    // PRELOAD IMAGES
    $.fn.preload = function () {
        this.each(function () {
            $('<img />')[0].src = this;
        });
    };

    if ($('#pageSponsor').length) {
        var oUrl = location.href.toString();
        var oRoot = oUrl.substr(0, oUrl.lastIndexOf('/') + 1);
        $(['https://cdn.bisnow.net/fit?height=97&type=png&url=https%3A%2F%2Fs3.amazonaws.com%2Fcdn.bisnow.net%2Fassets%2Fwebsite%2FsponsorStatsIcon1.png&width=64&sign=moV_ZTHmCKky1cWJvUmd2Q3zbX_SlzupOiFEzIEA-sM',
            'https://cdn.bisnow.net/fit?height=90&type=png&url=https%3A%2F%2Fs3.amazonaws.com%2Fcdn.bisnow.net%2Fassets%2Fwebsite%2FsponsorStatsIcon2.png&width=121&sign=OSepE0EKvPd8J_uldLG_q-EIpNaGRp56t3lB9sveTYY',
            'https://cdn.bisnow.net/fit?height=102&type=png&url=https%3A%2F%2Fs3.amazonaws.com%2Fcdn.bisnow.net%2Fassets%2Fwebsite%2FsponsorStatsIcon3.png&width=67&sign=itsYbRbYYGrBCpv4oIVMBttkl9Xue0KZZhPORrk_oKU',
            'https://cdn.bisnow.net/fit?height=49&type=png&url=https%3A%2F%2Fs3.amazonaws.com%2Fcdn.bisnow.net%2Fassets%2Fwebsite%2FsponsorStatsIconSmall1.png&width=32&sign=YPnEFmaF_BDfIyMfIEJH8siznLUC33nU6wY4O7djcgg',
            'https://cdn.bisnow.net/fit?height=46&type=png&url=https%3A%2F%2Fs3.amazonaws.com%2Fcdn.bisnow.net%2Fassets%2Fwebsite%2FsponsorStatsIconSmall2.png&width=62&sign=PPJE6-xKGd83lvcjrEm_bYNwjzdy4Ys4TWL_uoQGa80',
            'https://cdn.bisnow.net/fit?height=52&type=png&url=https%3A%2F%2Fs3.amazonaws.com%2Fcdn.bisnow.net%2Fassets%2Fwebsite%2FsponsorStatsIconSmall3.png&width=34&sign=trb6UkwPEZMfRFqYEdnnnzNlLhco4CIOJC25HnluRVA',
            'https://cdn.bisnow.net/fit?height=32&type=png&url=https%3A%2F%2Fs3.amazonaws.com%2Fcdn.bisnow.net%2Fassets%2Fwebsite%2FsponsorStatsIconTiny1.png&width=21&sign=QqRrOLO7yh02Swgj5Za-GVKQh2F7r8h7wNtXPS6xxVA',
            'https://cdn.bisnow.net/fit?height=30&type=png&url=https%3A%2F%2Fs3.amazonaws.com%2Fcdn.bisnow.net%2Fassets%2Fwebsite%2FsponsorStatsIconTiny2.png&width=40&sign=kSZ7hm0Qh8rLEgbXnx3cHuFOyusd7Sa5aii1WXGdLMQ',
            'https://cdn.bisnow.net/fit?height=33&type=png&url=https%3A%2F%2Fs3.amazonaws.com%2Fcdn.bisnow.net%2Fassets%2Fwebsite%2FsponsorStatsIconTiny3.png&width=22&sign=5VmWHdLkJD5A8T9tNtQDGBk3WJkfpusxQuXP6jeqlwY'
        ]).preload();
    }

    // COLLAPSING AND EXPANDING LONG BLOCKS OF TEXT
    $.fn.readmore = function (maxHeight, heightMargin) {
        var oBody = $(this);
        var oDuration = 600;
        oBody.each(function () {
            // set short height on the condition that the box is tall enough
            if ($(this).height() > maxHeight + heightMargin) {
                $(this).height(maxHeight).css('overflow', 'hidden').addClass('readMore collapsed');
                $(this).after('<button class="linkToggle" type="button">Read More</button>');
            }
            // set min height if the box does not contain enough content
            else {
                $(this).css('min-height', maxHeight + heightMargin);
            }
        });
        oBody.next('.linkToggle').click(function () {
            $('.bioContent').css('overflow', 'hidden');
            $('.bioContent').css('height', 'auto');
            var oLink = $(this);
            var currentBox = oLink.siblings('.readMore');
            var boxClone = currentBox.clone().css({
                'height': 'auto',
                'width': currentBox.width(),
                'overflow': 'hidden'
            }).insertAfter(currentBox);
            var boxHeight = boxClone.outerHeight(true);
            boxClone.remove();
            // if any box is currently opened then close it
            $('body').find('.readMore.expanded').animate({
                height: maxHeight
            }, oDuration, function () {
                $(this).removeClass('expanded').addClass('collapsed');
                $(this).next('.linkToggle').text('Read More');
            });
            // if current box is collapsed, animate to full height
            if (currentBox.hasClass('collapsed')) {
                currentBox.animate({
                    height: boxHeight
                }, oDuration, function () {
                    currentBox.removeClass('collapsed').addClass('expanded');
                    oLink.text('Close');
                });
            }
            // if current box is expanded, animate to short height
            else if (currentBox.hasClass('expanded')) {
                currentBox.animate({
                    height: maxHeight
                }, oDuration, function () {
                    currentBox.removeClass('expanded').addClass('collapsed');
                    oLink.text('Read More');
                });
            }
            return false;
        });
    };


    // TEAM BIOGRAPHY
    if ($('#pageAbout, #pageAllBios').length) {
        $(window).on('load', function () {
            $('.bioItem .bioInner').readmore(160, 22);
        });
    }


    // SMOOTH SCROLLING TO ANCHORS
    $('a[href^="#"]').click(function () {
        $('html, body').animate({
            scrollTop: Math.max(0, $($(this).attr('href')).offset().top - 150)
        }, 600);

        window.history.pushState({}, '', $(this).attr('href'));

        return false;
    });

    // ANCHORS FIX
    $.fn.anchorFix = function () {
        var oUrl = window.location.toString();

        if (window.location.hash && window.location.hash == '#_=_') {
        } else if (oUrl.indexOf('#') != -1) {
            var oAnchorId = oUrl.substr(oUrl.indexOf('#'));
            if (oAnchorId.indexOf('?') == -1 && $(oAnchorId).length) {
                $('html, body').delay(50).animate({
                    scrollTop: $(oAnchorId).offset().top
                }, 0);
            }
        }
    };
    $('body').anchorFix();

    // TABS
    $.fn.tabs = function () {
        var oBody = $(this);
        var oLink = oBody.find('menu a');
        var oContent = oBody.find('.content');

        // check if there's an active menu item
        if (!oLink.filter('.active').length) {
            oBody.find('menu li:first a').addClass('active');
        }

        // switch content
        oLink.click(function () {
            oLink.removeClass('active');
            $(this).addClass('active');
            var oIndex = $(this).parent().index();
            oContent.hide().eq(oIndex).show();
            return false;
        });

        // show active content after page load
        oLink.filter('.active').click();
    };
    $('.tabs').tabs();


    // NAV - RIGHT MENU - OPEN/CLOSE
    $.fn.rightMenuDropdown = function () {
        var oBody = $(this);
        var oParentSelector = '.rightMenu > ul > li';

        // prevent links from redirecting
        oBody.find('a.logIn, a.signUp').add('nav li.subMenu:not(".desktopHidden") > a').click(function () {
            return false;
        });

        // this is needed for the next function
        oBody.click(function (e) {
            if (!$(event.target).is("a")) {
                e.stopPropagation();
            }
        });

        // close sub menu, when user clicks somewhere outside
        $('body').click(function () {
            $(oParentSelector).removeClass('open');
        });

        // close sub menu, when user hovers another sub menu
        $(oParentSelector).mouseenter(function () {
            if (!$(this).is('.open')) {
                $(oParentSelector).removeClass('open');
            }
        });

        // close sub menu, when any link from main nav is hovered
        $('#header nav a').mouseenter(function () {
            $(oParentSelector).removeClass('open');
        });

        oBody.find('input').on('click', function () {
            var oParent = $(this).parents(oParentSelector);
            if (!oParent.is('.open')) {
                // close opened sub menu
                $(oParentSelector).removeClass('open');
                // open new sub menu
                oParent.addClass('open');
            }
        });
    };
    $('.rightMenu').rightMenuDropdown();


    // SUGGEST A SPEAKER SWITCH
    $('[name="person"]').change(function () {
        var oSpeakerBlock = $('.textInputsBlock.speakerInfo');
        if ($(this).attr('id') == 'thisisme') {
            oSpeakerBlock.slideUp().find('input.inin').removeClass('required');
        } else {
            oSpeakerBlock.slideDown().find('input.inin').addClass('required');
        }
    });

    // HEADER ANIMATION FUNCTION
    $.fn.animateHeader = function (option) {
        var oHeader = $(this);
        var oFakeHeader = $('#fakeHeader');
        var oFakeSubHeader = $('#fakeSubheader');
        var oSidebarTall = '80px';
        var oSidebarShort = '44px';
        var oDurationFirst = 0;
        var oDurationMain = 0;
        var oEasing = 'easeOutQuad';
        var oHeightTall = '76px';
        var oHeightShort = '44px';
        var oHeaderFixedShort = window.headerFixedShort ? true : false;
        var oAnimOpts = {
            easing: oEasing,
            queue: false,
            start: function () {
                $(this).css({
                    overflow: 'hidden'
                });
            },
            complete: function () {
                if (!$(this).is('a.logo')) $(this).css({
                    overflow: 'visible'
                });
                if (!oHeader.is('.init')) oHeader.addClass('init');
            }
        };

        if (option == 'init' && oHeaderFixedShort === true) {
            animateShort(oDurationFirst);
            $('#header').removeClass('tall').addClass('short')
        } else if (oHeaderFixedShort === true) {
            //;
        }
        // initial state
        else if (option == 'init') {
            if ($(window).scrollTop() <= oHeader.offset().top) {
                // init tall state - when a page is not scrolled
                animateTall(oDurationFirst);
            } else {
                // init short state -when page is scrolled
                animateShort(oDurationFirst);
            }
        }
        // animate to tall
        else if (option == 'tall') {
            if (oHeader.is('.init')) animateTall(oDurationMain);
        }
        // animate to short
        else if (option == 'short') {
            animateShort(oDurationMain);
        }

        return this;


        function animateTall(oDuration) {
            oHeader.animate({
                    height: oHeightTall
                },
                $.extend(oAnimOpts, {
                    duration: oDuration
                })
            );
            oHeader.find('.in').addBack().add(oFakeHeader).animate({
                    height: oHeightTall
                },
                $.extend(oAnimOpts, {
                    duration: oDuration
                })
            );
            oHeader.find('a.logo').animate({
                    marginTop: 22,
                    width: 130,
                    height: 35
                },
                $.extend(oAnimOpts, {
                    duration: oDuration
                })
            );
            $(".pageBisnowMatch").find('a.logo').animate({
                    marginTop: 22,
                    width: 180,
                    height: 28
                },
                $.extend(oAnimOpts, {
                    duration: oDuration
                })
            );
            oHeader.find('a .arrow').animate({
                    top: 37
                },
                $.extend(oAnimOpts, {
                    duration: oDuration
                })
            );
            oHeader.find('nav > ul > li > a').animate({
                    height: 76,
                    lineHeight: '76px'
                },
                $.extend(oAnimOpts, {
                    duration: oDuration
                })
            );
            oHeader.find('.rightMenu > ul > li > a, .rightMenu > ul > li > .search').animate({
                height: oHeightTall,
                lineHeight: oHeightTall
            }, $.extend(oAnimOpts, {
                duration: oDuration
            }));
        }

        function animateShort(oDuration) {
            oHeader.animate({
                    height: oHeightShort
                },
                $.extend(oAnimOpts, {
                    duration: oDuration
                })
            );
            oHeader.find('.in').addBack().add(oFakeHeader).animate({
                    height: oHeightShort
                },
                $.extend(oAnimOpts, {
                    duration: oDuration
                })
            );
            oHeader.find('a.logo').animate({
                    marginTop: 14,
                    width: 100,
                    height: 17
                },
                $.extend(oAnimOpts, {
                    duration: oDuration
                })
            );
            oHeader.find('a .arrow').animate({
                    top: 21
                },
                $.extend(oAnimOpts, {
                    duration: oDuration
                })
            );
            oHeader.find('nav > ul > li > a').animate({
                    height: 44,
                    lineHeight: '44px'
                },
                $.extend(oAnimOpts, {
                    duration: oDuration
                })
            );
            oHeader.find('.rightMenu > ul > li > a, .rightMenu > ul > li > .search').animate({
                height: oHeightShort,
                lineHeight: oHeightShort
            }, $.extend(oAnimOpts, {
                duration: oDuration
            }));
        }

    }; // animateHeader()


    // UNIVERSAL FIXED POSITION ON SCROLL
    $.fn.onScrollFixedPosition = function (oBodyClass) {
        // oBodyClass (str) - when the element gets fixed position, this class will be added to the body element

        // this function works for responsive pages
        var oBody = $(this);
        var oBodyPosition = oBody.offset().top;
        oBodyClass = (typeof oBodyClass !== 'undefined') ? oBodyClass : '';

        // we run the function on scroll window event
        $(window).on('scroll', function () {
            // if the offset position was changed (after adding a new element
            // or responsive layout changes) we need to update it
            if (oBodyPosition != oBody.offset().top && !oBody.is('.fixed')) {
                oBodyPosition = oBody.offset().top;
            }

            // add or remove class .fixed
            if ($(window).scrollTop() <= oBodyPosition && !oBody.is('#header')) {
                oBody.removeClass('fixed');
                $('body').removeClass(oBodyClass);
            } else if ($(window).scrollTop() > oBodyPosition && !oBody.is('#header')) {
                oBody.addClass('fixed');
                $('body').addClass(oBodyClass);
            }

            // the header must be fixed initially in order to avoid 'flickering' on mobile devices
            // we need a bit different condition in this case
            else if (oBody.is('#header')) {
                if ($(window).scrollTop() == 0) {
                    $('body').removeClass(oBodyClass);
                    if (!oBody.is('.tall') && !window.headerFixedShort) {
                        $('#header').removeClass('short').addClass('tall').animateHeader('tall');
                    }
                } else if ($(window).scrollTop() >= oBodyPosition) {
                    $('body').addClass(oBodyClass);
                    if (!oBody.is('.short')) {
                        $('#header').removeClass('tall').addClass('short').animateHeader('short');
                    }
                }
            }

        }); // scroll, resize

        // the following code is a fix. When a page is already scrolled when loaded,
        // and then page is scrolled to the very top, a little unknown jump occurred.

        if ($(window).scrollTop() > 0) {
            var oCurrentScrollTop = $(window).scrollTop();
            $(window).scrollTop(0);
            setTimeout(function () {
                $(window).scrollTop(oCurrentScrollTop);
            }, 1);
        }

    }; // onScrollFixedPosition()

    // call
    if ($('#header').length) {
        $('#header').animateHeader('init').onScrollFixedPosition('fixedHeader');
    }
    if ($('#pageSponsor').length) {
        if ($(".sponsorIcons").is(":visible")) {
            $(".contactAnchor").css('display', 'none');
            var topOfOthDiv = $(".sponsorIcons").offset().top;
            $(window).scroll(function () {
                if ($(window).scrollTop() > topOfOthDiv) { //scrolled past the other div?
                    $(".contactAnchor").show(500); //reached the desired point -- show div
                } else {
                    $(".contactAnchor").hide(500);
                }
            });
        }
    }


    // NAV - MEGA MENU POSITION
    $.fn.megaMenuPosition = function () {

        // get mega menus
        var oMegaSubMenu = $(this).find('.subMenuContent').filter(function () {
            return $(this).children().is('.megaSubMenu');
        });

        // each mega menu must be calculated separately
        oMegaSubMenu.each(function () {

            var oSubMenu = $(this);
            var oSubMenuWidth = oSubMenu.width();
            oSubMenu.show();
            var oInitialOffsetLeft = parseInt(oSubMenu.offset().left);
            oSubMenu.css({
                display: ''
            });
            var oMinWindowWidth = oSubMenuWidth + oInitialOffsetLeft;

            // change position, when window is resized
            $(window).resize(function () {
                var oWindowWidth = $(this).width();
                if (oWindowWidth < oMinWindowWidth) {
                    var oNewPosLeft = oSubMenu.parent('li').offset().left - (oWindowWidth - oSubMenuWidth);
                    oSubMenu.css({
                        left: -oNewPosLeft
                    });
                } else {
                    oSubMenu.css({
                        left: 0
                    });
                }
            });
            $(window).resize();

        }); // each
    };
    $('nav').megaMenuPosition();


    //---------------------- FAQ PAGE -----------------------//


    // FAQ OPEN/CLOSE ANSWERS
    $('#faq .q a').click(function () {
        if (!$(this).parents('.block').is(':animated')) {
            var oLink = $(this);
            // if it's opened - close
            if ($(this).is('.open')) {
                $(this).parent('.q').siblings('.a').slideUp(300, function () {
                    oLink.removeClass('open');
                });
            }
            // and if it's closed - open
            else {
                oLink.addClass('open');
                $(this).parent('.q').siblings('.a').slideDown(300);
            }
        }
        return false;
    });



    //---------------------- MOBILE NAV ----------------------//
    $.fn.mobileNav = function () {
        var oNav;

        // create
        if (!$('#mobNav').length) {
            $('body').append('<div id="mobNav" />');
        }
        oNav = $('#mobNav');

        // NAV - ACCORDION
        $.fn.mobNavAccordion = function () {
            var oBody = $(this);
            var oDuration = 200;
            var oEasing = 'linear';
            var oSubMenuClass = 'subMenuContent';
            var oOpenClass = 'open';

            // show default submenu
            oBody.find('li.' + oOpenClass + ' .' + oSubMenuClass).show();

            oBody.find('a').click(function () {
                // links don't work while animation is working
                if (oBody.find('.' + oSubMenuClass).is(':animated')) return false;
                else {
                    // if submenu exists - accordion, else - go to the link
                    if ($(this).siblings('.' + oSubMenuClass).length) {
                        var oMenu = $(this).siblings('.' + oSubMenuClass);
                        // if submenu is already open - close it
                        if (oMenu.parent().is('.' + oOpenClass)) {
                            oBody.find('.' + oOpenClass + ' .' + oSubMenuClass).slideUp(oDuration, oEasing, function () {
                                $(this).parent().removeClass(oOpenClass);
                            });
                        } else {
                            // close opened submenu
                            oBody.find('.' + oOpenClass + ' .' + oSubMenuClass).slideUp(oDuration, oEasing, function () {
                                $(this).parent().removeClass(oOpenClass);
                            });
                            // show submenu, that was clicked
                            oMenu.slideDown(oDuration, oEasing).parent().addClass(oOpenClass);
                        } // already opened

                        // if submenu is hidden on the inbetween width - go to the link
                        if (!($(this).parent().hasClass('desktopHidden') && $(window).width() >= 768)) {
                            return false;
                        }
                    } // submenu exists
                } // not animated
            }); // click
        };
        oNav.mobNavAccordion();

        // NAV - OPEN/CLOSE
        $.fn.mobNavAction = function (option) {
            var oPageWrapper = $('#wrapper');
            var oHeaderInner = $('#header .innerWrap');
            var oDuration = 200;
            var oEasing = 'swing';
            var oNavWidth = oNav.width();
            // open mobile nav
            if (option == 'open') {
                if (!oNav.is('.open') && !oPageWrapper.is(':animated')) {
                    oPageWrapper.css({
                        position: 'relative',
                        width: oPageWrapper.width()
                    });
                    oNav.addClass('open').animate({
                        left: 0
                    }, oDuration, oEasing);
                    // block all links in page content
                    $('a').filter(function () {
                        return !$(this).parents().is('#mobNav')
                    })
                        .bind('click.blockContentLinks', function (e) {
                            e.preventDefault();
                        });

                    $('body').addClass('noscroll');
                    $("#mobNav-background").css('display', 'block');
                    $('.at-share-dock-outer').hide();
                }
            }
            // close mobile nav
            else if (option == 'close') {
                if (oNav.is('.open') && !oPageWrapper.is(':animated')) {
                    oPageWrapper.animate({
                        left: 0
                    }, oDuration, oEasing, function () {
                        oPageWrapper.css({
                            position: 'static',
                            width: 'auto'
                        });
                    });
                    oNav.animate({
                        left: -oNavWidth
                    }, oDuration, oEasing, function () {
                        oNav.removeClass('open');
                    });
                    // animate header position
                    oHeaderInner.width('auto').animate({
                        left: 0
                    }, oDuration, oEasing);
                    // block all links in page content
                    $('a').filter(function () {
                        return !$(this).parents().is('#mobNav')
                    })
                        .unbind('click.blockContentLinks');
                    $('body').removeClass('noscroll');
                    $('.at-share-dock-outer').css('display', '');
                    $("#mobNav-background").css('display', '');
                }
            }
        }; // mobNavAction()


        // toggle mobile nav on click on button in header
        $.fn.mobNav = function (option) {
            if (!oNav.is('.open')) {
                oNav.mobNavAction('open');
                $('#wrapper').addClass('noscroll');
                $('.socialButtonsx').addClass('mobilehide');
                $('.contactAnchor').addClass('mobilehide');
                $('footer').css('padding-bottom', '0');
            } else {
                oNav.mobNavAction('close');
                $('#wrapper').removeClass('noscroll');
                $('.socialButtonsx').removeClass('mobilehide');
                $('.contactAnchor').removeClass('mobilehide');
                $('footer').css('padding-bottom', '38px');
            }
            return false;
        };

        $('#mobNavBtn').click($.fn.mobNav);

        // close mobile nav, when click on the page body
        $('#mobNav-background').click(function () {
            if (oNav.is('.open')) {
                oNav.mobNavAction('close');
                $('#wrapper').removeClass('noscroll');
            }
        });
        $('#header .innerWrap').click(function () {
            if (oNav.is('.open')) {
                oNav.mobNavAction('close');
                $('#wrapper').removeClass('noscroll');
            }
        });

        // close mobile nav when clicking on a link
        $('#mobNav .subMenuContent a').click(function () {
            oNav.mobNavAction('close');
            $('#wrapper').removeClass('noscroll');
        });


        // follow a link, when an option is selected
        oNav.find('select').on('change', function () {
            // check if selected option is not disabled
            if (!$(this).find(':selected').is(':disabled')) {
                window.location = $(this).val();
            }
        });

        // make sure that mobile nav is closed when page is being transformed to the desktop version
        $(window).resize(function () {
            if ($(window).width() > 1023 && oNav.is('.open')) {
                oNav.mobNavAction('close');
            }
        });

    };
    if ($('#header nav').length) $('#header').mobileNav();
    // mobile navigation


    //------------------------- FORMS --------------------------//


    // PLACEHOLDER FIX
    //$('input, textarea').placeholder();

    // CUSTOMIZED FORM ELEMENTS Z-INDEX
    // the function must be run, when all elements were customized
    $.fn.WFFormZindex = function () {
        var $page = $(this);
        var $elements = $page.find('.WFSelect');
        var $totalNumber = $elements.length;
        $elements.each(function () {
            $(this).css({
                zIndex: $totalNumber
            });
            $totalNumber--;
        });
    };
    $('body').WFFormZindex();


    // SUBSCRIPTION BOX - show the second box after clicking the button in the first box


    //------------------------- SHOW MORE PROFILES --------------------------//
    $.fn.profilesMore = function () {
        $('body').on('click', '.profiles-more', function (e) {

            if ($('.profiles-more').hasClass('inactive')) {
                return;
            }

            var nN = $('input[name=n]').serialize();
            var data = $('.primSend, input[name=_token]').serialize();
            data = data + '&showmore=true&' + nN;

            $.ajax({
                url: window.app_url + '/profiles/filter',
                method: 'get',
                dataType: 'json',
                data: data,
                success: function (data, textStatus, jqXHR) {
                    if (data.status == true) {
                        $('input[name="n"]').val(data.n);
                        $('#pageProfiles .profiles').append(data.html);
                        $('#pageProfiles .countprofiles').html(data.count);

                        var count = data.count.replace(' PROFILES', '');

                        if (data.returned >= 64) {
                            $('.block4 a').removeClass('hidden');
                            $('.profiles-more').html('+ SEE MORE +');
                        } else {
                            $('.profiles-more').html("THAT'S ALL").addClass('inactive');
                        }

                    } else {
                        alert("Please Try Your Search Again");
                    }
                }
            });
        });
    };
    $('body').profilesMore();


    //------------------------- PROFILES SEARCH --------------------------//
    $.fn.expandProfileSearch = function () {
        $('#pageProfiles .expand').bind('click', function (e) {
            if ($(this).hasClass('hide')) {
                $('#pageProfiles .block2 .content').animate({
                    height: "165px"
                }, 500);
                $('#pageProfiles .hide').html('+ SHOW ALL').removeClass('hide');
            } else {
                var iLength = $('.content.market .item').length;
                var iHeight = 56;
                var divisor = $(window).width() > 815 ? 2 : 1;
                var newHeight = (iLength * iHeight) / divisor;
                $('#pageProfiles .block2 .content').animate({
                    height: newHeight
                }, 500);
                $('#pageProfiles .expand').html('- HIDE ALL').addClass('hide');
            }
        });
    };
    $('body').expandProfileSearch();


    //------------------------- ACTIVATE/DEACTIVATE FILTERS --------------------------//

    $.fn.sendProfileFilter = function () {
        $('input[name=n]').val('');
        $('.profiles-more').removeClass('inactive');

        var cityParam = '',
            topicParam = '',
            concatParam = '',
            getParams = '';
        var city = $('#market_select').val();
        var topic = $('#topic_select').val();
        if (city) {
            cityParam = 'city=' + city;
        }
        if (topic) {
            concatParam = '&';
            topicParam = 'topic=' + topic;
        }

        getParams = cityParam + concatParam + topicParam;

        var filterUrl = '';
        if (getParams) {
            filterUrl = window.app_url + '/profiles/filter?' + getParams;
        } else {
            filterUrl = window.app_url + '/profiles/filter';
        }

        $.ajax({
            url: filterUrl,
            method: 'get',
            dataType: 'json',
            // data:$('.primSend, input[name=_token]').serialize(),
            success: function (data, textStatus, jqXHR) {
                // change state only if there are get params
                history.pushState(null, "Profiles Filter", "/profiles/filter?" + getParams);
                if (data.status == true) {
                    $('#pageProfiles .profiles').html(data.html);
                    $('#pageProfiles .countprofiles').html(data.count);

                    if (data.returned >= 65) {
                        $('.block4 a').removeClass('hidden');
                        $('.profiles-more').html('+ SEE MORE +');
                    } else {
                        $('.block4 a').addClass('hidden');
                    }

                    $('body').searchProfileRemoveFilter();
                } else {
                    alert("Please Try Your Search Again");
                }
            }
        });
    };


    $('#pageProfiles .checkButton').click(function () {
        var tog = !$(this).find('input').prop("checked");
        $(this).find('input').prop("checked", tog);
        if (tog) {
            $(this).find('div').css('background-color', 'rgb(0, 0, 0)');
            $('.clear-filers').append('<span class="' + $(this).attr('id') + '">' + $(this).find('div').html() + '</span>');
        } else {
            $(this).find('div').css('background-color', '#ccc');
            $('.clear-filers .' + $(this).attr('id')).remove();
        }
        $('body').sendProfileFilter();
    });


    //------------------------- REMOVE FILTERS --------------------------//
    $.fn.searchProfileRemoveFilter = function () {

        $('.clear-filers span').bind('click', function () {

            if ($(this).hasClass('search')) {
                $('input[name="q"]').val('');
            }

            if ($(this).attr('class').indexOf("g") > -1) {
                $('#' + $(this).attr('class')).trigger('click');
                return;
            }

            var currentValue = $('#pageProfiles input[name=filters]').val();
            var filterToRemove = $(this).attr('class').split(' ');
            var classificationId = filterToRemove[0];
            var selectType = filterToRemove[1];
            var text = currentValue.replace(classificationId + ",", "");
            $('#pageProfiles input[name=filters]').val(text);
            if (selectType == 'market_select') {
                var option = 'Choose Market';
            } else {
                var option = 'Choose Topic';
            }
            $('#' + selectType).parent().find('ul li span:first-child').html(option);
            $('#' + selectType).parent().find('ul li ul li.chosen').removeAttr('class');
            $('#' + selectType).parent().find('ul li ul li:first-child').addClass('chosen');
            $('#' + selectType + ' option:selected').removeAttr('selected');
            $(this).remove();

            $('body').sendProfileFilter();
        });

    };
    $('body').searchProfileRemoveFilter();


    //------------------------- Hide Contact Us when Typing on Mobile --------------------------//
    $.fn.hideContactUsMobile = function () {

        $('input').bind('click', function () {
            $('.contactAnchor').addClass('hidemobile');
            $('#footer').css('margin-bottom', '0');
        });

    };
    $('body').hideContactUsMobile();


    $.fn.searchProfiles = function () {

        $('#pageProfiles form').submit(function () {
            event.stopPropagation();
            event.preventDefault();
            $('span.clear-filers .search').remove();

            if ($('input[name="q"]').val() != '') {
                $('.clear-filers').append('<span class="search">Custom Search: ' + $('input[name="q"]').val() + '</span>');
            }

            $.ajax({
                url: window.app_url + '/profiles',
                method: 'post',
                headers: {
                    'X-CSRF-TOKEN': getCsrfToken()
                },
                dataType: 'json',
                data: $('#pageProfiles').find('form').serialize(),
                success: function (data, textStatus, jqXHR) {
                    if (data.status == true) {
                        $('#pageProfiles .profiles').html(data.html);
                        $('#pageProfiles .countprofiles').html(data.count);
                        $('body').searchProfileRemoveFilter();
                        $("html, body").animate({
                            scrollTop: $('#profiles').offset().top
                        }, 1000);
                    } else {
                        alert("Please Try Your Search Again");
                    }

                }
            });
        });
    };
    $('body').searchProfiles();


    //------------------------- UPLOAD --------------------------//

    var files;

    $('.upload input[type=file]').bind('change', function (event) {
        files = event.target.files;

        event.stopPropagation(); // Stop stuff happening
        event.preventDefault(); // Totally stop stuff happening

        if ($(".upload").hasClass("job-apply")) {
            return false;
        }

        $('.upload .over').html('UPLOADING FILE...');
        $('.upload input').hide();
        $('.error').html('');
        $('.message').html('');

        // Create a formdata object and add the files
        var data = new FormData();
        $.each(files, function (key, value) {
            data.append(key, value);
        });

        var _token = $('input[name=_token]').val();
        data.append('_token', _token);

        var url = $('.upload .url').val();

        $.ajax({
            url: url,
            type: 'POST',
            data: data,
            headers: {
                'X-CSRF-TOKEN': getCsrfToken()
            },
            cache: false,
            dataType: 'json',
            processData: false, // Don't process the files
            contentType: false, // Set content type to false as jQuery will tell the server its a query string request
            success: function (data, textStatus, jqXHR) {
                if (typeof data.error === 'undefined' || data.status == 'success') {
                    // Success so call function to process the form
                    $('.upload .over').html('CHOOSE NEW FILE');
                    $('.upload input').show();
                    $('.upload .error').html('Upload Successful');
                    $('.upload .message').html('Click "Choose New File" to upload a different file.');
                    $('.resume_url').val(data.resume_url);
                } else {
                    // Handle errors here
                    $('.upload .over').html('CHOOSE FILE');
                    $('.upload .error').html(data.error);
                    $('.upload input').show();
                    //$('input[type=file]').on('change', prepareUpload);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                // Handle errors here
                // STOP LOADING SPINNER
                $('.upload .over').html('CHOOSE FILE');
                $('.upload .error').html('Error: Please Upload Again');
                $('.upload input').show();
                //$('.upload').html('ERROR: PLEASE UPLOAD AGAIN');
                //$('.upload').append('<input type="file" name="resume_saved" style="display: block;">');
            }
        });
    });


    $('.upload.job-apply input[type=file]').bind('change', function (event) {
        files = event.target.files;

        event.stopPropagation(); // Stop stuff happening
        event.preventDefault(); // Totally stop stuff happening

        var file_name = $('.upload.job-apply input[type=file]')[0].files[0].name;
        $('.upload .over').html('FILE UPLOADED - CHOOSE NEW FILE');
        $('.upload .file-name').html(file_name);

    });


    // URL Parameters
    var classUrlUtil = {
        parameter: function (name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        }
    };
    window.urlUtil = classUrlUtil;


    // SWIPE TO SHOW/HIDE MENUS
    //---------------------------------------------------------------------------------------------------------


    // YouTube Video Resize
    $.fn.youtube_iframe = function () {
        var youtube_width = $(".youtube-iframe").width();
        var youtube_height = youtube_width * 9 / 16;
        $(".youtube-iframe").attr("height", youtube_height);
    };
    $('body').youtube_iframe();

    $(window).on('resize', function () {
        $('window').youtube_iframe();
    });


    var classFormAction = {
        checkboxBtnsBind: function () {
            var self = this;

            $("form .btn-options label").unbind().bind("click", function () {
                self.checkboxBtns(this);
            });
        },

        checkboxBtns: function (label) {
            var label = label;
            var checkbox = $(this).attr("for");

            if (!$(label).hasClass('selected')) {
                $(label).addClass('selected');
            } else {
                $(label).removeClass('selected');
            }
        },
    };

    window.FormAction = classFormAction;
    FormAction.checkboxBtnsBind();

});


// EASING TYPES
jQuery.easing['jswing'] = jQuery.easing['swing'];

jQuery.extend(jQuery.easing, {
    // t: current time, b: begInnIng value, c: change In value, d: duration

    def: 'easeOutQuad',
    swing: function (x, t, b, c, d) {
        //alert(jQuery.easing.default);
        return jQuery.easing[jQuery.easing.def](x, t, b, c, d);
    },
    easeOutQuad: function (x, t, b, c, d) {
        return -c * (t /= d) * (t - 2) + b;
    }
});

// Redirect for Invalid Anchor
var anchor = window.location.hash;
if (anchor.substring(0, 2) === '#.') {
    var current_url = window.location.href.match(/(^[^#]*)/);
    window.location.replace(current_url[0]);
}

// Redirect if iFrame
if (window.self !== window.top) {
    window.top.location.href = window.location.href;
}
