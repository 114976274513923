$( function() { {
        const typeAhead = $(".js-type-ahead")

        if (typeAhead) {

            //Workaround for a select2 bug so it autofocuses on the search field when they click into the input
            $(document).on('select2:open', (event) => {
                const searchField = document.querySelector(
                    `.select2-search__field[aria-controls="select2-${event.target.getAttribute('data-select2-id')}-results"]`,
                );
                if (searchField) {
                    searchField.focus()
                }
            })

            //Workaround so it opens select2 on tab into the input
            //on first focus (bubbles up to document), open the menu
            $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
                $(this).closest(".select2-container").siblings('select:enabled').select2('open')
            })

            // steal focus during close - only capture once and stop propogation
            $('select.select2').on('select2:closing', function (e) {
                $(e.target).data("select2").$selection.one('focus focusin', function (e) {
                    e.stopPropagation();
                })
            })
        }
    }
})