(function() {

  var TemplateUtilsImpl = {

    grabTemplate: function(id) {
      var html = $.parseHTML( $(id).html() );
      return $( html );
    },

    grabTemplateFromJson: function(template) {
      if (!template || !template.length) {
        return '';
      }
      var html = template.replace(/\\/g, '');
      html = $.parseHTML( html );
      return $( html );
    }

  };

  var FormUtilsImpl = {
    serialize: function(formOrId) {
      var data = {}, form = $(formOrId), self = this;

      // Only fields that are visible will be displayed (this does not exclude type="hidden" fields)
      $('input[name],select[name],textarea[name]', form).each(function (index, input) {
        if ( ! $(input).parent().is(':visible')) {
          return;
        }

        var type = $(input).attr('type');
        var name = $(input).attr('name');
        var value = $(input).val();

        if (type === 'radio' || type === 'checkbox') {
          if ( ! $(input).is(':checked') ) {
            return;
          }
        }

        data[name] = value;
      });
      return data;
    },

    fill: function(formOrId, data) {
      var key, value, form = $(formOrId);
      for (key in data) {
        value = data[key];
        $('input[name="' + key + '"],textarea[name="' + key + '"],select[name="' + key + '"]', form).val( value );
      }
    },

    reset: function(formOrId) {
      var form = $(formOrId);
      $('input[name],select[name],textarea[name]', form).val('');
    }
  };


  var AjaxUtilsImpl = {
    _ajax: function (type, url, data, successCallback, errorCallback) {
      $.ajax(url, {
        type: type,
        headers: {'X-CSRF-TOKEN': getCsrfToken(), 'Content-Type': 'application/json'},
        data: JSON.stringify(data),
        success: function (response) {
          if (response.error) {
            Log.error(url);
            Log.error(response.message);
            Log.error(response.trace);

            if (errorCallback) {
              errorCallback(response.message, response.trace);
            }
            else {
              alert('Error: ' + response.message);
            }
          }
          else {
            successCallback && successCallback(response);
          }
        },
        error: function (xhr, status, errorThrown) {
          Log.error(url);
          Log.error(errorThrown);
          Log.error(xhr.responseText);

          var message = xhr.responseText || errorThrown;

          if (errorCallback) {
            errorCallback(message, null);
          }
          else {
            alert('System error: ' + message);
          }
        }
      });
    },

    postAjax: function(url, data, successCallback, errorCallback) {
      this._ajax('post', url, data, successCallback, errorCallback);
    },

    getAjax: function(url, data, successCallback, errorCallback) {
      this._ajax('get', url, data, successCallback, errorCallback);
    }
  };


  /**
   * VALIDATION UTILS ARE ONLY FOR FRONTEND.
   * These use a very specific layout for highlighting erroneous fields.
   *
   */
  var ValidationUtilsImpl = {

    /**
     * Setups up a basic jquery validation plugin to work with a FRONTEND bisnow form. However, this includes
     * no validation rules, these need be be added using the .rules() method of the returned object.
     *
     * @param string|Form form
     *
     * @returns {*} a jquery.validate object
     */
    getOrSetupFormValidation: function(form, rules, submitHandler, messages) {
      // If the form parameter is a class-selector, fail.
      if (typeof form == 'string' && !/^[#].*/.test(form)) {
        throw new TypeError('Only ID selectors are supported: \'' + form + '\' is not valid');
      }

      // Check if form exists
      if (jQuery(form).length < 1) {
          return jQuery( form );
      }

        var domForm = jQuery(form)[0];

        // Create a new validation context
      var validation = $(form).validate({
        errorClass: 'validationError',

        highlight: function (element, errorClass, validClass) {
          $(element).parent('div').addClass(errorClass);
          $('.error', $(element).parent('div')).show();
        },

        unhighlight: function (element, errorClass, validClass) {
          $(element).parent('div').removeClass(errorClass);
          $('.error', $(element).parent('div')).hide();
        },

        errorPlacement: function(error,element) {
          // Display the error message
          $('.formError', $(element).parent('div')).text( $(error).text() );
        },

        rules: rules || {},

        messages: messages || {},

        submitHandler: function(form) {
          submitHandler && submitHandler(form);
        }
      });

      // Store it with the DOM Form
      domForm._validationCtx = validation;

      // Return it
      return jQuery( form );
    },

    reset: function(form) {
      var validation = this.setupBasicValidation(form);
      validation.resetForm();

      $( '.error', $(form)).hide();
      $( '.validationError', $(form)).removeClass('validationError');
    }
  };

  var AccordionUtil = {
    initAcc: function (elem, option){
      document.addEventListener('click', function (e) {
          if (!e.target.matches(elem+' .a-btn')) return;
          else{
            if(!e.target.parentElement.classList.contains('active')){
                if(option){
                    var elementList = document.querySelectorAll(elem+' .a-container');
                    Array.prototype.forEach.call(elementList, function (e) {
                        e.classList.remove('active');
                    });
                }
                e.target.parentElement.classList.add('active');

                setTimeout(function() {
                  window.scroll({
                    top: $(elem+' .a-container.active').offset().top - $('#header').height(),
                    behavior: 'smooth',
                  });
                }, 300);
            }else{
                e.target.parentElement.classList.remove('active');
            }
          }
      });
    }
  };

  // Export
  window.Utils = {
    Template: TemplateUtilsImpl,
    Form: FormUtilsImpl,
    Ajax: AjaxUtilsImpl,
    Validation: ValidationUtilsImpl,
    Accordion: AccordionUtil
  };

})();
