(function() {

  var Pagination = {
	paginate: function(data) {
		if(data.array.length > data.perPage) {
			this.fillPage(data)
			this.makeButtons(data)

			const buttons = data.buttons.querySelectorAll('.paginationButton')
			buttons.forEach((button) => {
				button.addEventListener('click', this.changePage.bind(this, button, data))
			})
		}
    },

	fillPage(data) {
		const spliced = data.array.slice((data.currentPage - 1) * data.perPage, data.currentPage * data.perPage)

		if (spliced.length) {
			let rows = []

			for (let i = 0; i < spliced.length; i++) {
				if (data.preRow && data.postRow) {
					rows.push(data.preRow + spliced[i].innerHTML + data.postRow)
				} else {
					rows.push(spliced[i].innerHTML)
				}
			}
			
			data.container.innerHTML = rows.join("")
		}
	},

    makeButtons(data) {
		const buttonCount = Math.ceil(data.array.length / data.perPage)

		let numbers = []

		for (let i = 0; i < buttonCount; i++) {
			let value = i + 1
			let current = value == data.currentPage ? 'is-current' : ''

			numbers.push('<span id="' + value + '" class="paginationButton ' + data.container.id + ' ' + current + '">' + value + '</span>')

		}

		const prev = '<span id="prev" class="paginationButton ' + data.container.id + '"></span>'
		const next = '<span id="next" class="paginationButton ' + data.container.id + '"></span>'


		if (data.currentPage == 1) {
			data.buttons.innerHTML = numbers.join("") + next
		} else if (data.currentPage == buttonCount) {
			data.buttons.innerHTML = prev + numbers.join("")
		} else {
			data.buttons.innerHTML = prev + numbers.join("") + next
		}
    },

	changePage(button, data) {
		let value = button.id 

		if (value == 'prev') {
			value = parseInt(data.currentPage) - 1
		} else if (value == 'next') {
			value = parseInt(data.currentPage) + 1
		}

		data.currentPage = value
		this.paginate(data)
	}
  };

  // Export
  window.Pagination = Pagination;

})();
