(function () {

    "use strict";

    /**
     * Function to format strings
     * "{0} and {1} and {2}".format('first', 'second', 'third');
     */
    if (typeof String.prototype.format != 'function') {
        String.prototype.format = function () {
            var args = arguments;
            return this.replace(/{(\d+)}/g, function (match, number) {
                return typeof args[number] != 'undefined' ?
                    args[number] :
                    match;
            });
        };
    }

    window.isMobileBrowser = function () {
        var check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    };

    /**
     * PUBLIC function
     * Returns an integer value if the decimal is a whole number; otherwise returns a number with 2 decimal places.
     *
     * @param decimal the decimal number to fix
     * @returns {*}
     */
    window.util_fixedDecimal = function (decimal) {
        // Make sure it's a number not a string
        decimal = 1 * decimal;
        // Make sure the number only has decimal places if it's truly a fraction
        // Otherwise remove the decimal places
        if (Math.round(decimal) !== decimal) {
            return decimal.toFixed(2)
        }
        return decimal.toFixed(2);
    };

    /**
     * PUBLIC function
     * Returns a dollar amount (with dollar symbol). As an integer number if the dollar amount is a whole number;
     * otherwise returns the dollar amount with 2 decimal places.
     *
     * @param decimal the dollar amount to fix
     * @returns {string}
     */
    window.util_dollarDecimal = function (decimal, symbol) {
        symbol = symbol || '$';
        if (decimal < 0) {
            return '-' + symbol + util_fixedDecimal(Math.abs(decimal));
        } else {
            return symbol + util_fixedDecimal(decimal);
        }
    };


    window.util_usdDecimal = function (decimal) {
        return util_dollarDecimal(decimal, "$");
    };

    window.util_gbpDecimal = function (decimal) {
        return util_dollarDecimal(decimal, "£");
    };

    window.util_eurDecimal = function (decimal) {
        return util_dollarDecimal(decimal, "€");
    };

    // COMMON JQUERY EXTENSIONS
    $(function () {

        $.fn.initAcc = function (elem, option) {
            document.addEventListener('click', function (e) {
                if (!e.target.matches(elem+' .a-btn')) return;
                else{
                    if(!e.target.parentElement.classList.contains('active')){
                        if(option==true){
                            var elementList = document.querySelectorAll(elem+' .a-container');
                            Array.prototype.forEach.call(elementList, function (e) {
                                e.classList.remove('active');
                            });
                        }
                        e.target.parentElement.classList.add('active');
                    }else{
                        e.target.parentElement.classList.remove('active');
                    }
                }
            });
        }

        /**
         * Makes a global loading button, in place, by adding or replacing content with a spinner image.
         *
         * Use the <code>options</code> argument to pass settings as an object, as following:
         *  - { append: true / false }: specify whether the spinner image is appended, otherwise it replaces all content
         *
         * @param options used to specify settings
         */
        $.fn.makeLoading = function (options) {
            var height = $(this).outerHeight();
            var width = $(this).outerWidth();
            //var topPadding = (height - 28) / 2;
            var topPadding = 0;
            var loaderImg = $(this).hasClass('orange') ? 'https://cdn.bisnow.net/assets/website/ajax-loader-square.gif' : 'https://cdn.bisnow.net/assets/website/ajax-loader-square-black.gif';
            var ajlColor = $(this).hasClass('orange') ? '#F58320' : '#000000';

            options = $.extend({
                append: false
            }, options);

            $(this).css('height', height).css('width', width).css('background-color', ajlColor);
            $(this).attr('style', function (i, s) {
                return s + 'padding-left: 0 !important; padding-right: 0 !important;'
            });
            var img = '<img class="spinner" src="' + loaderImg + '"/>';

            $(this).attr('disabled', 'disabled');
            $(this).children('input').attr('disabled', 'disabled');

            if (options.append) {
                // Hide the original content
                $(' > *', this).css('visibility', 'hidden');
                $(this).css('position', 'relative');

                // Convert the image to an object, then append it
                $(this).append($(img));
            } else {
                // Replace all html with the new image html
                $(this).html(img);
            }
        };

        $.fn.makeLoadingAdmin = function () {
            var height = $(this).outerHeight();
            var width = $(this).outerWidth();
            var loaderImg = 'https://cdn.bisnow.net/assets/website/admin/ajax-loader.gif';

            var img = '<img class="spinner" style="margin-top: 3px;width:28px;height:28px;padding-top:0px" src="' + loaderImg + '"/>';

            // Hide the original content
            $(this).css('visibility', 'hidden');
            $(this).css('position', 'relative');

            // Convert the image to an object, then append it
            var htmlReplace = $(this).before('<div class="makeLoadingAdmin" style="text-align: center; margin-bottom: -34px;"></div>');
            $('.makeLoadingAdmin').append(img).css({
                background: '#000000',
                text: 'center',
                height: height,
                width: width
            });
        };

        $.fn.toggleButtonLoading = function (text) {
            $(this).find('a').text(text)
        };

        /**
         * After <code>makeLoading</code> is called on an element, call <code>unmakeLoading</code> on that same element to
         * undo changes, including:
         *  - hide the spinner image
         *  - restore original conent (only if append:true setting was used when calling <code>makeLoading</code>)
         *
         */
        $.fn.unmakeLoading = function () {
            var el = $(this);
            $('.spinner', el).remove();
            $(' > *', el).css('visibility', 'visible');
            $(el).css('visibility', 'visible');
            $(el).siblings('.makeLoadingAdmin').remove();
            el.css('width', '').css('background-color', '').css('height', '').css('color', '').css('paddingLeft', '').css('paddingRight', '');

            $(this).removeAttr('disabled');
            $(this).children('input').removeAttr('disabled');
        };

        $.fn.stopLoading = function (options) {
            var settings = $.extend({
                // These are the defaults.
                text: "Submit",
                styleString: '',
                buttonStyleString: ''
            }, options);
            var curstyle = $(this).attr('style');
            $(this).attr('style', curstyle + ';' + settings.styleString).css('background-color', 'inherit');
            $(this).html('<button style="' + settings.buttonStyleString + '">' + settings.text + '</button>');
        };


        $.fn.isFormValidated = function (required, options) {
            let isValidated = true
            let message = ''
            // Pass in IDs or class selector path (#id-to-element or .any-class-here)
            required.forEach((target, i) => {
              const value = $(target).val()
              if (!value || value.trim() === '') {
                isValidated = false
                message = 'Please complete the form'
              }
            })

            if (options && options.email) {
              options.email.forEach((target, i) => {
                // validate email
                const email = $(target).val()
                const isValidEmail = String(email).toLowerCase()
                  .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  );
                // if email is invalid, return false
                if (!isValidEmail) {
                  isValidated = false
                  message = 'Please enter a valid email.'
                }
              });
            }

            if (options && options.phone) {
              options.phone.forEach((target, i) => {
                // validate email
                const phone = $(target).val()
                const isValidPhone = String(phone).toLowerCase()
                  .match(
                    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
                  );
                // if phone is invalid, return false
                if (!isValidPhone) {
                  isValidated = false
                  message = 'Please enter a valid phone number.'
                }
              });

            }

            return {
              status: isValidated,
              message: message
            }
        }

        // FUNCTION TO HIGHLIGHT

        $.fn.highlight = function (durationIn, durationOut, callback) {
            var self = this;
            var backgroundColor = $(self).finish().css('backgroundColor');
            $(self).animate({
                backgroundColor: '#ffffc9'
            }, durationIn || 300, function () {
                $(self).animate({
                    backgroundColor: backgroundColor
                }, durationOut || 100, function () {
                    $(self).css('background-color', backgroundColor);
                    if (callback)
                        callback();
                });
            });

            return this;
        };

        $.fn.doubleHighlight = function () {
            var self = this;
            $(self).highlight(500, 300, function () {
                setTimeout(function () {
                    $(self).highlight(300, 100);
                }, 250);
            });
        };

        /**
         * Will serialize a FORM into an JS object format
         * http://stackoverflow.com/a/1186309/1000437
         * @returns {*}
         */
        $.fn.serializeObject = function () {
            var o = {};
            var a = this.serializeArray();
            $.each(a, function () {
                if (o[this.name] !== undefined) {
                    if (!o[this.name].push) {
                        o[this.name] = [o[this.name]];
                    }
                    o[this.name].push(this.value || '');
                } else {
                    o[this.name] = this.value || '';
                }
            });
            return o;
        };

        /**
         * Similar to $.extend, except that it performs a deep extension of object sub-properties, plus leaves intact
         * original properties if present in all objects.
         * @param objToExtend
         * @returns {*}
         */
        $.deepExtend = function (objToExtend /* , ... */) {
            for (var i = 1; i < arguments.length; ++i) {
                var obj = arguments[i];
                if (obj == objToExtend) {
                    continue;
                }

                for (var key in obj) {
                    var type0 = typeof objToExtend[key];
                    var type1 = typeof obj[key];

                    if (type0 == type1) {
                        switch (type0) {
                            case 'object':
                                objToExtend[key] = $.deepExtend(objToExtend[key], obj[key]);
                                break;
                        }
                    } else if (type0 == 'undefined') {
                        objToExtend[key] = obj[key];
                    }
                }

                return objToExtend;
            }
        };
    });
})();
