$(function () {
    if (typeof addthis !== "undefined") {
        addthis.init();
        addthis.addEventListener('addthis.ready', function () {
            override_sharethis_email_click(2);
        });
    }
});

var _lightbox = null;

function setup_lightbox() {
    if (null != _lightbox) {
        $(_lightbox).remove();
    }

    _lightbox = $('<div id="bisnow-lightbox"></div>');
    $('body').append(_lightbox);
    return _lightbox;
}

function validate_share_email() {
    var valid = true;
    $('#email-modal form input[type="text"]').each(function (index, object) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        valid &= re.test($(object).val());
    });

    return valid;
}

function setup_share_email() {
    _lightbox = setup_lightbox();
    var email = $('#bisnow-email').clone();
    $('body').append(email);
    $(email)
        .css('position', 'absolute')
        .css('display', 'block')
        .css('left', $(_lightbox).width() / 2 - $(email).width() / 2)
        .css('top', '0px');

    // Bootstrap the submit button
    $('input[type="submit"]', email).click(function (event) {
        event.preventDefault();

        if (!validate_share_email(email)) {
            $('.message', email).html("<span class='error'>Both To and From email fields must be valid</span>");
            return;
        } else {
            $('.message *', email).remove();
        }

        var form_data = $('form', email).serialize();
        Log.info('about to share article using data:');
        Log.info(form_data);

        $.ajax('/share/email', {
            headers: {
                'X-CSRF-TOKEN': getCsrfToken()
            },
            type: 'post',
            data: form_data,
            success: function (response) {
                $('.message', email).html("<span class='success'>Success sharing story!</span>");

                $('input[type="submit"]', email).attr('value', 'Close').unbind().click(function (event) {
                    close_share_email(email);
                });
            }
        });

        return false;
    });

    $('.close', email).click(function (event) {
        close_share_email(email);
    });

    $(email).animate({
        'top': '250px'
    }, 'slow');
}

function close_share_email(email) {
    $(email).animate({
        'top': -$(email).height()
    }, 'fast', function () {
        $(_lightbox).fadeOut(function () {
            $(_lightbox).remove();
            $(email).remove();
            _lightbox = null;
        });
    });
}

var _email_modal = null;

function override_sharethis_email_click(times) {
    if (null == _email_modal) {
        $('#email-modal .copy-to-clipboard').unbind().click(function () {
            window.prompt("Copy to clipboard: Ctrl+C, Enter", $('#share_url').val());
        });

        // Bootstrap the submit button
        $('#email-modal .email-share-btn').click(function (event) {
            event.preventDefault();

            if (!validate_share_email($('#email-modal'))) {
                $('#email-modal .message').html("<span class='error'>Both To and From email fields must be valid</span>");
                return;
            } else {
                $('#email-modal .message *').remove();
            }

            var form_data = $('#email-modal form').serialize();

            $.ajax('/share/email', {
                type: 'post',
                headers: {
                    'X-CSRF-TOKEN': getCsrfToken()
                },
                data: form_data,
                success: function (response) {
                    // Hide the email body/footer and display a message saying Thanks!
                    $('#email-modal .modal-footer').fadeOut();
                    $('#email-modal .modal-body').fadeOut(function () {
                        $('<h1 class="thanks" style="margin: 20px auto; text-align: center;">Shared!</h1>').insertAfter('#email-modal .modal-header').fadeIn(function () {
                            setTimeout(function () {
                                $('#email-modal').fadeOut(function () {
                                    _email_modal.modal('hide');
                                    $('#email-modal .thanks').remove();
                                    $('#email-modal').css('opacity', 1);
                                    $('#email-modal .modal-body').show();
                                    $('#email-modal .modal-footer').show();
                                });
                            }, 600);
                        });
                    });
                    $('#email-modal input[type="text"]').val("");
                }
            });

            return false;
        });

        _email_modal = $('#email-modal').modal({
            show: false,
            onHide: function (dialog) {
                dialog.data.fadeOut('slow', function () {
                    dialog.container.hide('slow', function () {
                        dialog.overlay.slideUp('slow', function () {
                            $.modal.hide();
                        });
                    });
                });
            }
        });
    }

    if (!times) return;
    setTimeout(function () {
        let shareEmailFunction = function (event) {
            event.stopPropagation();

            // Open up the custom email pane
            //setup_share_email();
            _email_modal.modal('show');

            override_sharethis_email_click(--times);
        };

        $('.at-svc-email').unbind();
        $('.at-svc-email').click(shareEmailFunction);

        $('.at-svc-mailto').unbind();
        $('.at-svc-mailto').click(shareEmailFunction);
    }, 1000);
}
