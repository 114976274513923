$(function () {
    $("#mobSubscribeBtn").addClass('hidden');
    loadUserData();

    $('BODY').on('BN_user_data_loaded', function (response) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'user_detail',
            'login_status': response.id ? 'Logged In' : 'Not Logged In',
            'user_id': response.ga_userid || 'Not Applicable'
        });
    });
});

function hoverLogin() {
    $('.subMenu').on('mouseover', function () {
        $('.dropContent', this).show();
    }).on('mouseout', function (e) {
        if (!$(e.target).is('input')) {
            $('.dropContent', this).hide();
        }
    });
}

function authCheck(data) {
    if (isGuest(data)) {
        $(".subMenu-subscribe").show();
        $("#mobNav-guest").removeClass('hidden');
        if (data.cart && (data.cart['total_quantity'] === undefined || data.cart['total_quantity'] === 0)) {
            $("#mobSubscribeBtn").removeClass('hidden');
        }
        return false;
    }

    $(".subMenu-newsletters").show();
    $("#mobNav-user").removeClass('hidden');

    pushlyProfile(data);
    return true;
}

function flash(data) {
    if (data.flash !== undefined && data.flash.class !== undefined && data.flash.class) {
        $('.flash-message').removeClass('hidden').addClass(data.flash.class).html(data.flash.message);
    }
}

function isGuest(data) {
    if (data['id'] === undefined) {
        return true;
    }

    return false;
}

function isUser(data) {
    if (data['id'] === undefined) {
        return false;
    }

    return true;
}

function pushlyProfile(data) {
    if (process.env.MIX_APP_ENV !== "production") {
        return;
    }

    if (typeof window.pushlyLoaded !== "boolean") {
        return;
    }

    if (window.pushlyLoaded === false) {
        setTimeout(function () {
            pushlyProfile(data);
        }, 3000);
        return;
    }


    if (data.email === "undefined" || !data.email) {
        window.pushly("profile", {
            "known_user": false
        });
        return false;
    }

    window.pushly('profile', {
        'first_name': data.firstname,
        'user_id': data.id,
        'known_user': true
    });
    window.pushly('external_id', data.id);
}

function fillInHTML(data) {
    $("#header_partial").html(data.header);
    $("#subscribe_modal").html(data.subscribe_modal);
}

function formToken(data) {
    $("input[name='_token']").val(data._token);
    $("body").attr('data-csrf', data._token);
}

function subscribePopout(data) {
    if (!('subscribe_popout' in data)) {
        $(".scroll-newsletter-popup")
            .removeClass('always-hide-popup');
    } else {
        $(".scroll-newsletter-popup")
            .removeClass('show-popup')
            .addClass('always-hide-popup');
    }
}

function gdprPopup(data) {
    // User location in EU
    if (('gdpr_modal' in data)) {
        $("#gdpr_modal").modal({ backdrop: 'static', keyboard: false, show: true });
    } else {
        $("#gdpr_modal").modal('hide');
    }
}

function gdprOptedOut(data) {
    $("#gdpr_opted_out").modal('hide');

    // User has globally unsubscribed and hasn't seen this modal during this session
    if ((data['global_unsubscribe'] === 'true')) {
        $("#gdpr_opted_out").modal({ backdrop: 'static', keyboard: false, show: true });
    }
}

function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
    });
    return vars;
}

function getParamName(name) {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results == null) {
        return null;
    }
    return decodeURI(results[1]) || 0;
}

function isFullAdmin(data) {
    if (typeof data.groups != "undefined" && data.groups.length > 0) {
        if ($.inArray("Full Admin", data.groups) !== -1) {
            $('#link-to-edit-page').show();
        }
    }
}

function setProductPopup(productId) {
    var currentLocation = window.location.pathname.split('/');
    if ((currentLocation[1] != 'events' && currentLocation[1] != 'checkout') || (currentLocation[1] === 'events' && currentLocation.length === 2)) {
        $.ajax({
            // url: '{{ secure_url("/api/products/v2/") }}/' + productId,
            url: process.env.MIX_APP_URL + "/api/products/v2/" + productId,
            type: "get",
            success: function (data) {
                var product = data.productable;
                if (data.productable_type !== 'ReEvent') {
                    return;
                }
                var eventDate = new Date(product.date_start);
                // var link = '{{ secure_url("/checkout/v2/") }}/' + productId;
                var link = process.env.MIX_APP_URL + "/checkout/v2/" + productId;
                $('#eventCartPopout .popupEventName').html(product.name);
                $('#eventCartPopout .popupEventDate').html(eventDate.toDateString());
                $('#eventCartPopout .popupEventLink').attr('href', link);

                setTimeout(function () {
                    $('#eventCartPopout').show();
                    $('#eventCartPopout').addClass('active');
                }, 5000);
            }
        });

        $('body').on('click', 'a#closePopout', function (e) {
            e.preventDefault();
            $('#eventCartPopout').removeClass('active').hide();
        });
    }
}

function setCartVersion(version) {
    $('body').removeClass('cart-v1').removeClass('cart-v2').addClass('cart-v' + version);
}

function viewCount(storyIndexData) {
    var view_count_enabled = false;
    var total_view_count = 0;

    for (var key in storyIndexData) {
        view_count_enabled = storyIndexData[key].view_count_enabled;
        total_view_count = storyIndexData[key].total_view_count;

        var title_line = storyIndexData[key].title_line;

        if (total_view_count == null) {
            total_view_count = 0;
        }

        if (view_count_enabled) {
            $(".content-" + key + " .view-count .view-count-number").html(total_view_count);
            $(".view-count").show();

            $(".content-" + key + " .story-title-line").html(title_line);
            $(".story-title-line").show();
        }
    }
}

function loadUserData() {
    var random_title = new URLSearchParams(window.location.search).get('rt');
    $.ajax({
        url: process.env.MIX_APP_URL + "/user/data",
        type: 'GET',
        data: {
            rt: random_title,
            url: encodeURI(window.location.href),
            url_referrer: encodeURI(document.referrer),
            email: getUrlVars()["email"],
            contact_hash: getUrlVars()["contact_hash"],
            cart_version: getUrlVars()["cart_version"],
            be: getUrlVars()["be"],
            contentID: window.contentId,
            contentIDs: window.contentIds
        },
        success: function (data) {
            if (data) {
                authCheck(data);
                fillInHTML(data);

                // we filled in the html
                // lets remove the html from the data object
                delete data.cartHTML;
                delete data.header;
                delete data.subscribe_modal;

                window.$user = data;
                formToken(data);
                subscribePopout(data);

                if (data.storyIndexData) {
                    viewCount(data.storyIndexData);
                }

                if (window.articleDataLayer) {
                    window.articleDataLayer['user'] = isUser(data) ? data.id : null;

                    if (window.articleDataLayer.hasOwnProperty('poNumber')) {
                        window.dataLayer.push({
                            'event': 'articleView',
                            'article': articleDataLayer
                        });
                    }
                }

                gdprPopup(data);
                gdprOptedOut(data);
                isFullAdmin(data);
                setCartVersion(data.cart_version);
                flash(data);
                if (data.cart_v2_product) {
                    setProductPopup(data.cart_v2_product);
                }
            } else {
                window.$user = null;
            }

            hoverLogin();

            var utmSourceParam = getParamName('utm_source');

            if (utmSourceParam && utmSourceParam.lastIndexOf('outbound_pub_', 0) === 0) {
                data.bypassGate = true;
            }

            var userDataLoaded = $.Event('BN_user_data_loaded', data);
            window.user_data = JSON.stringify(data);
            $('body').trigger(userDataLoaded);
        },
        error: function (xhr, textStatus, error) {
            var userDataError = $.Event('BN_user_data_loaded', null);
            $('body').trigger(userDataError);
        }
    });
}
